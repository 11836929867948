import React from "react"
import {Container} from 'reactstrap'
import {graphql} from "gatsby";

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import BloqueImagenTexto from "../components/bloqueImagenTexto";
import {LangContext} from "../components/lurraldebusWrapper";


const NormativaPage = ({ data }) => (
  <LangContext.Consumer>
    { context => (
      <Layout>
        <SEO title={context.translate(data, 'normativa', 'titulo')} />
        <Container className="py-5 mb-5">
          <PageTitle title={context.translate(data, 'normativa', 'titulo')}/>
          {context.translate(data, 'normativa', 'normativas').map(item =>
            <BloqueImagenTexto
              key={item.id}
              banner={item.banner}
              title={item.encabezado}
              children={item.texto}
            />
          )}
        </Container>
      </Layout>
    )}
  </LangContext.Consumer>
)

export default NormativaPage

export const pageQuery = graphql`  
  query {
    normativa: strapiNormativa {
      normativas {
        id
        encabezado
        texto
        banner {
          publicURL
        }
      }
      titulo
    }
    normativa_eus: strapiNormativaEus {
      normativas {
        id
        encabezado
        texto
        banner {
          publicURL
        }
      }
      titulo
    }
  }
`